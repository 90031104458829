/* Modal Styles */
.modal {
  position: absolute; /* Absolute within parent container */
  left: 0;
  right: 0;
  bottom: -100%; /* Start off-screen */
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  margin-bottom: 5rem;
  min-height: 30%;
  background: linear-gradient(
      to bottom,
      rgba(7, 2, 74, 0) 0%,
      rgba(7, 2, 74, 1) 100%
    ),
    #422bd2;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  outline: none;
  transition: bottom 500ms ease-in-out, background 500ms ease-in-out;
}

/* When the modal is opened */
.modal-open {
  bottom: 0; /* Slide into view */
}

/* When the modal is closed */
.modal-close {
  bottom: -100%; /* Slide out of view */
}

/* Overlay Styles */
.overlay {
  position: absolute; /* Absolute within parent container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0); /* Transparent by default */
  z-index: 12; /* Below the modal */
  opacity: 0;
  transition: opacity 500ms ease-in-out, background-color 500ms ease-in-out;
}

.overlay-pop-up {
  z-index: 9; /* Below the modal */
}

.overlay-open {
  opacity: 1;
  background-color: rgba(7, 3, 71, 0.75); /* Semi-transparent overlay */
}

.overlay-close {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0); /* Transparent */
}
