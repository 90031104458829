.reel-container {
  width: 17vw;
  height: 53.4vw;
  position: relative;
  overflow: hidden;
  mask-image: linear-gradient(
    to bottom,
    transparent,
    black 10%,
    black 90%,
    transparent
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent,
    black 10%,
    black 90%,
    transparent
  );
}

.bg {
  position: absolute;
  top: -10.4%;
  height: calc(120.8%);
  width: calc(151.7% * 1); /* Scale the width to 140% (100% + 20% padding) */
  object-fit: cover;
}

.background-container {
  height: 49.2vw;
  aspect-ratio: calc(392 / 679);
  position: absolute;
}

.reel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.symbol {
  width: 17vw;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.4vw;
  margin-top: 0.4vw;
  z-index: 3;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Slightly increase size */
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(10deg);
  }
}

@keyframes glow {
  0%,
  100% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
    border-radius: 50%;
  }
  50% {
    box-shadow: 0 0 20px rgb(0, 204, 255, 0.4);
    border-radius: 50%;
  }
}
