::-webkit-scrollbar {
  display: none;
}

@media (min-height: 800px) {
  /* Styles for devices with height greater than or equal to 800px */
  .slots-title {
    display: block;
  }
}

/* For devices with a maximum height of 600px */
@media (max-height: 670px) {
  /* Styles for devices with height less than or equal to 600px */
  .slots-title {
    display: none;
  }
}
