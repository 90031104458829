.app-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.content-container {
  position: relative;
  padding-top: 4rem;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
  overflow-y: auto;
}