p {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.user-select-none {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */
}
