@media (min-height: 800px) {
    /* Styles for devices with height greater than or equal to 800px */
    .bet-input-title {
      display: block;
    }
  }
  
  /* For devices with a maximum height of 600px */
  @media (max-height: 670px) {
    /* Styles for devices with height less than or equal to 600px */
    .bet-input-title {
      display: none;
    }
  }
  