@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Agbalumo&display=swap");

html {
  overflow: hidden;
  height: 100%;
}

@font-face {
  font-family: "Gotham";
  src: url("assets/fonts/Gotham-Bold.otf") format("opentype");
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("assets/fonts/Gotham-Medium.otf") format("opentype");
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "Gang of Three";
  src: url("assets/fonts/go3v2.ttf") format("truetype");
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "Posterable";
  src: url("assets/fonts/POSTERABLE.ttf") format("truetype");
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "Alien Encounters";
  src: url("assets/fonts/Alien-Encounters-Regular.ttf") format("truetype");
  font-weight: 500; /* Medium */
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #07024a;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: white;
  height: 100%;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.agbalumo-regular {
  font-family: "Agbalumo", system-ui;
  font-weight: 400;
  font-style: normal;
}

.gotham-bold {
  font-family: "Gotham", sans-serif;
  font-weight: 700;
}

.gotham-medium {
  font-family: "Gotham", sans-serif;
  font-weight: 500;
}

.gang-of-three {
  font-family: "Gang of Three", sans-serif;
  font-weight: 500;
}

.posterable {
  font-family: "Posterable", sans-serif;
  font-weight: 500;
}

.alien-encounters {
  font-family: "Alien Encounters", sans-serif;
  font-weight: 500;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.break-line-on-space {
  white-space: pre-wrap; /* Allows wrapping at whitespace */
  word-break: break-word; /* Break long words onto the next line */
  display: inline-block; /* Ensure the element respects the break */
}

.gradient-border {
  position: relative;
  border-radius: 12px; /* Adjust the radius as needed */
  background: white; /* Set background to white or any other color for the content area */
}

.gradient-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 2px; /* Adjust this value based on the border width */
  background: linear-gradient(
    to top,
    rgba(68, 46, 223, 0),
    rgba(68, 46, 223, 1)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  background-clip: border-box;
  z-index: -1; /* Ensure the gradient border is behind the content */
}

.gradient-text {
  background: linear-gradient(to top, #f5f7ff, #617bff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.gradient-text-green {
  background: linear-gradient(to top, #15cf05, #91f934);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.gradient-text-red {
  background: linear-gradient(to top, #f54b42, #ff9a8b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.blur-wrapper {
  overflow: hidden;
}

.blur {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-filter: blur(15px);
  filter: blur(15px);
}
