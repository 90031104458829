.pop-up {
  position: absolute;
  top: 50%;
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%) scale(0.8); /* Center and scale */
  max-height: 50vh; /* Limit the height to 50% of the viewport */
  min-width: 70%;
  z-index: 9999;
  background-color: #221487;
  border-radius: 0.8rem;
  outline: none;
  opacity: 0;
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  padding: 20px; /* Padding for inner content */
  width: auto; /* Let width adjust based on content */
  max-width: 90%; /* Optional: Prevent the pop-up from exceeding 90% of viewport width */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.pop-up-borders {
  border: 2px solid rgba(66, 43, 210, 0.75);
  border-radius: 0.5rem;
  width: 100%;
}

/* Pop-up appears */
.pop-up-open {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

/* Pop-up disappears */
.pop-up-close {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.8);
}
